<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 class="text-h5 mb-4">개인정보 처리방침</h2>
        <div>
        베리타스 S 서비스 내 이용자 식별, 회원관리 및 서비스 제공을 위해 필요한 최소한의 개인정보를 수집하고 있습니다. 해당 정보는 동의 철회 또는 서비스 탈퇴 시 지체없이 파기됩니다. 아래 동의를 거부할 권리가 있으며, 필수 동의를 거부할 경우 서비스 이용이 제한됩니다.
        선택 동의를 거부할 경우 기재된 목적의 일부 서비스 이용이 제한될 수 있습니다.
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider></v-divider>
        <div class="font-weight-bold">[제공 받는 자]</div>
        <div>(주)골든이스트에듀</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider></v-divider>
        <div class="font-weight-bold">[필수 제공 항목]</div>
        <div>카카오계정(전화번호)</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="font-weight-bold">[선택 제공 항목]</div>
        <div>이름</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="font-weight-bold">[제공 목적]</div>
        <div>베리타스 S 서비스 내 이용자 식별, 회원관리 서비스 제공</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="font-weight-bold">[보유 기간]</div>
        <div>동의 철회 또는 서비스 탈퇴 시 지체없이 파기</div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup>
</script>