<template>
  <v-app>
    <v-main class="main">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {},


  data: () => ({
    //
  }),
}
</script>
<style scoped>
.main {
  background-color: #fafafa;

}
</style>